@media print {

    html,
    .pdf-list {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    body {
        font-size: 12pt;
        line-height: 1.35;
        padding: 0;
        margin: 0;
    }

    .pdf-list h1 {
        position: running(header);
    }
}

@page {
    size: A4;
    margin-left: 1.5cm;
    margin-bottom: 2.4cm;
    margin-top: 1.8cm;

    @top-left {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        padding-top: 1cm;
        content: element(header);
    }

    @top-right {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        padding-top: 1cm;
        content: "Seite " counter(page);
    }
}

@page:first {
    @top-right {
        display: none;
    }
}


.pdf-list-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pdf-list-item {
    position: relative;
    page-break-inside: avoid;
    margin-bottom: 10pt;

    &.no-text {
        margin-bottom: 4pt;
    }

    &.show-date {
        margin-top: 14pt;
    }

    &.show-date + .no-date,
    &.no-date + .no-date {
        page-break-before: avoid;
    }
}

.pdf-dateinfo {
    margin-bottom: 8pt;
}

.pdf-list {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-family: inherit;
        margin-top: 0;
        margin-bottom: 2pt;
        line-height: 1.2;
    }

    h1 {
        font-size: 18pt;
        margin-bottom: 14pt;
    }

    h2,
    h3 {
        font-size: 14pt;
    }

    h1,
    h2 {
        font-weight: bold;
    }

    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
    }

    .gd-head {
        padding-left: 70pt;
        text-indent: -70pt;
    }

    .gd-time {
        display: inline-block;
        width: 70pt;
        min-width: 70pt;
        text-indent: 0;
    }

    .ap-list-badge {
        text-indent: 0;
    }

    .gd-content {
        padding-left: 70pt;
    }

    .gd-intention,
    .gd-celebrant,
    .gd-headline,
    .gd-text {
        display: inline;
    }

    .gd-intention {
        font-style: italic;
    }

    .gd-content {
        line-height: 1.1;
        padding-left: 70pt;
    }

    .gd-content-top {
        margin-bottom: 3pt;
    }

    .gd-headline + .gd-text {
        margin-left: 0;
    }

    .gd-intention + .gd-celebrant::before {
        content: " - ";
    }

    .gd-headline + .gd-text::before {
        content: ": ";
    }

    .direktorium {

        .rang .dbem,
        .rang .dheader,
        .rangG .dheader,
        .rangg .dheader {
            display: none;
        }

        .rangH .dheader,
        .grad1 .dheader,
        .grad2 .dheader,
        .grad3 .dheader,
        .grad4 .dheader,
        .grad5 .dheader {
            display: block;
            font-weight: bold;
            font-size: inherit;
            line-height: inherit;
        }

        .rangF .dheader,
        .grad6 .dheader,
        .grad13 .dheader {
            display: block;
            font-size: inherit;
            line-height: inherit;
        }

        &.hfonly > div,
        &.hfonly > div.rangH > .dbem {
            display: none;
        }

        &.hfonly > div.rangH {
            display: block;
        }
    }
}

