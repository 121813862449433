@media print {
  html,
.pdf-list {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  body {
    font-size: 12pt;
    line-height: 1.35;
    padding: 0;
    margin: 0;
  }

  .pdf-list h1 {
    position: running(header);
  }
}
@page {
  size: A4;
  margin-left: 1.5cm;
  margin-bottom: 2.4cm;
  margin-top: 1.8cm;
  @top-left {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding-top: 1cm;
    content: element(header);
  }
  @top-right {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding-top: 1cm;
    content: "Seite " counter(page);
  }
}
@page :first {
  @top-right {
    display: none;
  }
}
.pdf-list-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pdf-list-item {
  position: relative;
  page-break-inside: avoid;
  margin-bottom: 10pt;
}
.pdf-list-item.no-text {
  margin-bottom: 4pt;
}
.pdf-list-item.show-date {
  margin-top: 14pt;
}
.pdf-list-item.show-date + .no-date, .pdf-list-item.no-date + .no-date {
  page-break-before: avoid;
}

.pdf-dateinfo {
  margin-bottom: 8pt;
}

.pdf-list h1,
.pdf-list h2,
.pdf-list h3,
.pdf-list h4,
.pdf-list h5,
.pdf-list h6,
.pdf-list p {
  font-family: inherit;
  margin-top: 0;
  margin-bottom: 2pt;
  line-height: 1.2;
}
.pdf-list h1 {
  font-size: 18pt;
  margin-bottom: 14pt;
}
.pdf-list h2,
.pdf-list h3 {
  font-size: 14pt;
}
.pdf-list h1,
.pdf-list h2 {
  font-weight: bold;
}
.pdf-list h3,
.pdf-list h4,
.pdf-list h5,
.pdf-list h6 {
  font-weight: normal;
}
.pdf-list .gd-head {
  padding-left: 70pt;
  text-indent: -70pt;
}
.pdf-list .gd-time {
  display: inline-block;
  width: 70pt;
  min-width: 70pt;
  text-indent: 0;
}
.pdf-list .ap-list-badge {
  text-indent: 0;
}
.pdf-list .gd-content {
  padding-left: 70pt;
}
.pdf-list .gd-intention,
.pdf-list .gd-celebrant,
.pdf-list .gd-headline,
.pdf-list .gd-text {
  display: inline;
}
.pdf-list .gd-intention {
  font-style: italic;
}
.pdf-list .gd-content {
  line-height: 1.1;
  padding-left: 70pt;
}
.pdf-list .gd-content-top {
  margin-bottom: 3pt;
}
.pdf-list .gd-headline + .gd-text {
  margin-left: 0;
}
.pdf-list .gd-intention + .gd-celebrant::before {
  content: " - ";
}
.pdf-list .gd-headline + .gd-text::before {
  content: ": ";
}
.pdf-list .direktorium .rang .dbem,
.pdf-list .direktorium .rang .dheader,
.pdf-list .direktorium .rangG .dheader,
.pdf-list .direktorium .rangg .dheader {
  display: none;
}
.pdf-list .direktorium .rangH .dheader,
.pdf-list .direktorium .grad1 .dheader,
.pdf-list .direktorium .grad2 .dheader,
.pdf-list .direktorium .grad3 .dheader,
.pdf-list .direktorium .grad4 .dheader,
.pdf-list .direktorium .grad5 .dheader {
  display: block;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
}
.pdf-list .direktorium .rangF .dheader,
.pdf-list .direktorium .grad6 .dheader,
.pdf-list .direktorium .grad13 .dheader {
  display: block;
  font-size: inherit;
  line-height: inherit;
}
.pdf-list .direktorium.hfonly > div, .pdf-list .direktorium.hfonly > div.rangH > .dbem {
  display: none;
}
.pdf-list .direktorium.hfonly > div.rangH {
  display: block;
}

/*# sourceMappingURL=service-pdf.css.map */